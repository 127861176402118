import '../styles/main.scss'

import React, {useCallback, useEffect} from 'react'
import type {AppProps} from 'next/app'
import Head from 'next/head'
import {appWithTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import {QueryParamProviderComponent} from '../components/shared/QueryParamProvider'
import Script from 'next/script'
import {PageProps} from './[[...slug]]'
import {Error404Page} from './404'
import {GTMProvider} from '../utils/hooks/useGTM'

declare const window: any

function MyApp({Component, pageProps}: AppProps<PageProps | Error404Page>) {
  const path = React.useRef<string>('')
  const router = useRouter()
  const pushPageViewData = useCallback((site: any, page: any) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({event: 'virtualPageview', site, page})
  }, [])

  const reloadOTBanner = useCallback(() => {
    if (window.OneTrust != null) {
      window.OneTrust.Init()
      setTimeout(() => {
        window.OneTrust.LoadBanner()
        const toggleDisplay = document.getElementsByClassName(
          'ot-sdk-show-settings',
        )
        for (let i = 0; i < toggleDisplay.length; i += 1) {
          toggleDisplay[i].addEventListener('click', event => {
            event.stopImmediatePropagation()
            window.OneTrust.ToggleInfoDisplay()
          })
        }
      }, 1000)
    }
  }, [])

  const handleRouteChange = useCallback(() => {
    if (path.current !== router.asPath) {
      window.dataLayer = window.dataLayer || []
      const site = {
        environment:
          pageProps?.environment === 'prd' ? 'production' : 'staging',
        country: router.locale?.split('-')?.[1],
        language: router.locale?.split('-')?.[0],
      }
      const page = {
        contentType: pageProps?.page?.type
          .replace('node--', '')
          .replace('_', ' '),
      }
      pushPageViewData(site, page)
      path.current = router.asPath
    }

    reloadOTBanner()
  }, [
    pageProps?.environment,
    pageProps?.page?.type,
    pushPageViewData,
    reloadOTBanner,
    router.asPath,
    router.locale,
  ])

  useEffect(() => {
    handleRouteChange()
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [handleRouteChange, router])

  return (
    <QueryParamProviderComponent>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      {process.env.APP_ENV === 'prd' ? (
        <Script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-P8B2DN3');`,
          }}
          strategy="afterInteractive"
        />
      ) : (
        <Script
          id="gtm"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=hUyeUfwYz5WZtB6cj9jU4Q&gtm_preview=env-3&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-P8B2DN3');`,
          }}
          strategy="afterInteractive"
        />
      )}
      <GTMProvider>
        <Component {...pageProps} />
      </GTMProvider>
    </QueryParamProviderComponent>
  )
}

export default appWithTranslation(MyApp)
